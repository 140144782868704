import Phaser from 'phaser';

export class BallGameScene extends Phaser.Scene {
  private ball!: Phaser.Physics.Arcade.Sprite;
  private cursors!: Phaser.Types.Input.Keyboard.CursorKeys;
  private obstacles!: Phaser.Physics.Arcade.Group;
  private background!: Phaser.GameObjects.TileSprite;
  private score: number = 0;
  private score_occurence: number = 0;
  private scoreText!: Phaser.GameObjects.Text;
  private isGameOver: boolean = false; // Variable pour gérer l'état de fin de jeu

  private debugMode: boolean = false;
  private debugText!: Phaser.GameObjects.Text;

  private velocity = window.innerWidth * 0.3;
  private obstacleSpeed = -200; // Vitesse des obstacles et du fond
  private obstacleTypes = ['obstacle1', 'obstacle2', 'obstacle3'];

  constructor() {
    super({ key: 'BallGameScene' });
  }

  preload() {
    this.load.image('grass', 'assets/game/gazon.png');
    this.load.image('ball1', 'assets/game/balle1.png');
    this.load.image('ball2', 'assets/game/balle2.png');
    this.load.image('ball3', 'assets/game/balle3.png');
    this.load.image('ball4', 'assets/game/balle4.png');
    this.load.image('ball5', 'assets/game/balle5.png');
    this.load.image('ball6', 'assets/game/balle6.png');
    this.load.image('ball7', 'assets/game/balle7.png');
    this.load.image('ball8', 'assets/game/balle8.png');
    this.load.image('ball9', 'assets/game/balle9.png');
    this.load.image('ball10', 'assets/game/balle10.png');
    this.load.image('ball11', 'assets/game/balle11.png');
    this.load.image('ball12', 'assets/game/balle12.png');
    this.load.image('ball13', 'assets/game/balle13.png');
    this.load.image('ball14', 'assets/game/balle14.png');
    this.load.image('ball15', 'assets/game/balle15.png');
    this.load.image('ball16', 'assets/game/balle16.png');
    this.load.image('obstacle1', 'assets/game/obstacle1.png'); // Obstacle de type 1
    this.load.image('obstacle2', 'assets/game/obstacle2.png'); // Obstacle de type 2
    this.load.image('obstacle3', 'assets/game/obstacle3.png'); // Obstacle de type 3
  }
  
  create() {
    this.anims.create({
        key: 'roll',
        frames: [
          { key: 'ball1' },
          { key: 'ball2' },
          { key: 'ball3' },
          { key: 'ball4' },
          { key: 'ball5' },
          { key: 'ball6' },
          { key: 'ball7' },
          { key: 'ball8' },
          { key: 'ball9' },
          { key: 'ball10' },
          { key: 'ball11' },
          { key: 'ball12' },
          { key: 'ball13' },
          { key: 'ball14' },
          { key: 'ball15' },
          { key: 'ball16' },
        ],
        frameRate: 8,
        repeat: -1,
      });

    // Ajouter le fond défilant
    this.background = this.add.tileSprite(0, 0, this.scale.width, this.scale.height, 'grass');
    this.background.setOrigin(0, 0);

    const zoom = (window.innerWidth > window.innerHeight) ? 0.1 : 0.3;
    const ballSize = Math.min(window.innerWidth, window.innerHeight) * zoom;
    this.ball = this.physics.add.sprite(70, 300, 'ball1').setDisplaySize(ballSize, ballSize);
    this.ball.play('roll');
    this.ball.setCircle(this.ball.width / 2);
    this.ball.setCollideWorldBounds(true);

    this.obstacles = this.physics.add.group();

    this.time.addEvent({
      delay: 2000,
      callback: this.addObstacle,
      callbackScope: this,
      loop: true
    });

    // Afficher le score avec un arrière-plan
    const backgroundOverlay = this.add.rectangle(
      10,
      10,
      this.scale.width * 2,
      80,
      0x000000,
      0.4
    ); // Fond semi-transparent pour le score

    this.scoreText = this.add.text(10, 10, 'Score: 0', {
      fontSize: '40px',
      color: '#fff',
    });

    backgroundOverlay.setDepth(4); // Mettre le score au premier plan
    this.scoreText.setDepth(5); // Mettre le score au premier plan

    if (this.input?.keyboard) {
      this.cursors = this.input.keyboard.createCursorKeys();
    }

    this.input.on('pointerdown', (pointer: Phaser.Input.Pointer) => {
      if (!this.isGameOver) {
        if (pointer.y < this.game.scale.height / 2) {
            this.ball.setVelocityY(-this.velocity);
        } else {
            this.ball.setVelocityY(this.velocity);
        }
      }
    });

    this.input.on('pointerup', () => {
      if (!this.isGameOver) {
        this.ball.setVelocityY(0);
      }
    });

    this.physics.add.collider(this.ball, this.obstacles, () => {
        this.cameras.main.shake(100); // Tremblement d'écran
        this.gameOver();
    });
      

    if(this.debugMode){
        const backgroundDebugOverlay = this.add.rectangle(
            10,
            this.scale.height - 90,
            this.scale.width * 2,
            40,
            0x000000,
            0.4
          ); // Fond semi-transparent pour le score
      
        this.debugText = this.add.text(10, this.scale.height - 110, 'Ball Y:'+ this.ball.y, {
            fontSize: '40px',
            color: '#fff',
        });
    }
  }

  override update() {
    if (this.isGameOver) return; // Si le jeu est terminé, arrête la mise à jour

    this.score_occurence++;
    this.score = Math.floor(this.score_occurence / 60);
    this.scoreText.setText(`Score: ${this.score}`);

    if(this.debugMode){
        this.debugText.setText('Ball Y:'+ this.ball.y);
    }

    // Synchroniser le défilement du fond avec la vitesse des obstacles
    this.background.tilePositionX += Math.abs(this.obstacleSpeed / 60);
  }

  addObstacle() {
    if (this.isGameOver) return; // Empêche la création d'obstacles en fin de jeu
  
    const x = window.innerWidth + 200;
    const y = Phaser.Math.Between(50, window.innerHeight - 50);
  
    // Choix aléatoire d'un type d'obstacle
    const randomObstacle = Phaser.Utils.Array.GetRandom(this.obstacleTypes);
  
    const obstacle = this.obstacles.create(x, y, randomObstacle) as Phaser.Physics.Arcade.Image;
    obstacle.setVelocityX(this.obstacleSpeed);
    obstacle.setSize(obstacle.width * 0.8, obstacle.height * 0.8).setOffset(obstacle.width * 0.1, obstacle.height * 0.1);
  
    if (obstacle.body) {
      obstacle.body.world.on('worldbounds', () => {
        obstacle.destroy();
      });
    }
  }  

  gameOver() {
    this.isGameOver = true; // Met le jeu en état "terminé"
    this.ball.setVelocity(0, 0); // Stoppe la balle

    this.obstacles.children.iterate((child) => {
        const obstacle = child as Phaser.Physics.Arcade.Image;
        obstacle.setVelocityX(0); // Arrête les obstacles
        return null; // Indique que l'itération continue
    });         

    // Ajouter un rectangle semi-transparent pour l'effet sombre
    const backgroundOverlay = this.add.rectangle(
      this.scale.width / 2,
      this.scale.height / 2,
      this.scale.width,
      this.scale.height,
      0x000000,
      0.6
    ).disableInteractive(); // Empêche le rectangle de capturer les clics
    
    // Affiche le Score
    const title = this.add.text(
        this.scale.width / 2,
        this.scale.height / 2 - 150,
        'Conduite de balle',
        {
          fontSize: '30px',
          color: '#fff',
          fontStyle: 'bold',
          align: 'center',
        }
      ).setOrigin(0.5);

    // Affiche le texte Game Over
    const gameOverText = this.add.text(
      this.scale.width / 2,
      this.scale.height / 2 - 50,
      'Game Over',
      {
        fontSize: '40px',
        color: '#F00000',
        fontStyle: 'bold',
        align: 'center',
      }
    ).setOrigin(0.5);

    // Affiche le Score
    const gameOverScoreText = this.add.text(
        this.scale.width / 2,
        this.scale.height / 2 + 150,
        'Score : ' + this.score,
        {
          fontSize: '40px',
          color: '#f00000',
          fontStyle: 'bold',
          align: 'center',
        }
      ).setOrigin(0.5);

    // Ajoute un bouton pour recommencer
    const restartButton = this.add.text(
      this.scale.width / 2,
      this.scale.height / 2 + 50,
      'Recommencer',
      {
        fontSize: '24px',
        color: '#ffffff',
        backgroundColor: '#007bff',
        padding: { x: 10, y: 5 },
        align: 'center',
      }
    )
    .setOrigin(0.5)
    .setInteractive({ useHandCursor: true }) // Rend le bouton cliquable
    .on('pointerdown', () => {
        this.scene.restart(); // Redémarre la scène
        this.isGameOver = false;
        this.score = 0;
        this.score_occurence = 0;
    });

    // Ajoute un effet visuel sur le bouton lors du survol
    restartButton.on('pointerover', () => {
      restartButton.setStyle({ backgroundColor: '#0056b3' });
    });
    restartButton.on('pointerout', () => {
      restartButton.setStyle({ backgroundColor: '#007bff' });
    });

    // Ajuster les profondeurs pour l'écran de Game Over
    backgroundOverlay.setDepth(1);
    title.setDepth(5);
    gameOverText.setDepth(5);
    gameOverScoreText.setDepth(5);
    restartButton.setDepth(10);
  }
}
