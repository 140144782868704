import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import Phaser from 'phaser';
import { BallGameScene } from './ball-game-scene';

@Component({
  selector: 'app-ball-game',
  template: `<div #gameContainer></div>`,
  styles: [`
    :host {
      display: block;
      width: 80%;
      height: 50%;
      max-width: 400px;
    }
    div {
      width: 100%;
      height: 100%;
    }
  `]
})
export class BallGameComponent implements OnInit, OnDestroy {
  @ViewChild('gameContainer', { static: true }) gameContainer!: ElementRef;
  private phaserGame!: Phaser.Game;

  ngOnInit(): void {
    const config: Phaser.Types.Core.GameConfig = {
      type: Phaser.AUTO,
      width: window.innerWidth,
      height: window.innerWidth * 16/9,
      parent: this.gameContainer.nativeElement,
      physics: {
        default: 'arcade',
        arcade: {
          debug: false
        }
      },
      input: {
        activePointers: 1, // Permet d’avoir au moins un pointeur actif
      },
      scene: [BallGameScene],
      scale: {
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH
      }
    };
  
    this.phaserGame = new Phaser.Game(config);
  
    // Redimensionner dynamiquement en cas de changement
    window.addEventListener('resize', this.resizeGame.bind(this));
  }
  
  resizeGame() {
    if (this.phaserGame.isBooted) {
      this.phaserGame.scale.resize(window.innerWidth, window.innerHeight);
    }
  }
  
  ngOnDestroy(): void {
    if (this.phaserGame) {
      this.phaserGame.destroy(true);
    }
  
    window.removeEventListener('resize', this.resizeGame.bind(this));
  }  
}
