<div fxLayout="column" fxFlex="100" class="columnDiv">
    <h2 fxLayout="row" fxFlex="100" fxLayoutAlign="center center">Documents</h2>
    <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
        <div fxLayout="row" fxFlex="100" fxLayoutAlign="center center">
            <button mat-flat-button color="primary" fxFlex="50" *ngIf="isDocument()">
                <mat-icon>description</mat-icon> Association
            </button>
            <button mat-stroked-button fxFlex="50" *ngIf="!isDocument()" (click)="selectDocument()">
                <mat-icon>description</mat-icon> Association
            </button>
            <button mat-flat-button color="primary" fxFlex="50" *ngIf="isCoffreFort()">
                <mat-icon>lock</mat-icon> Coffre Fort
            </button>
            <button mat-stroked-button fxFlex="50" *ngIf="!isCoffreFort()" (click)="selectCoffreFort()">
                <mat-icon>lock</mat-icon> Coffre Fort
            </button>
        </div>
        <mat-card appearance="outlined" *ngIf="selectedEntry === undefined">
            <mat-card-content style="font-style: italic; background-color: gainsboro;">Aucune catégorie sélectionnée</mat-card-content>
        </mat-card>
        <!-- PARTIE DOCUMENTS D'ASSOCIATION -->
        <div *ngIf="isDocument()" fxLayout="column"fxFlex="100" fxLayoutAlign="center center">
            <div *ngIf="fileList.length === 0">
                <mat-spinner></mat-spinner>
            </div>
            <mat-card *ngFor="let file of fileList" fxLayout="column" fxFlex="100" style="margin-top:10px;" class="columnDiv">
                <mat-card-title fxFlex="100" fxLayoutAlign="center center">
                    {{ file.name }}<span *ngIf="file.size !== null">&nbsp;- {{ formatBytes(file.size,2) }}</span>
                </mat-card-title>
                <mat-card-content fxFlex="100" class="columnDiv">
                    <mat-divider></mat-divider>
                    <p>
                        <strong>&Eacute;dition:</strong>&nbsp;{{ file.update | date:'dd/MM/yyyy' }}
                    </p>
                    <p><strong>Description:</strong> {{ file.description }}</p>
                </mat-card-content>
                <mat-card-actions fxFlex="100" fxLayoutAlign="center center">
                    <button mat-raised-button *ngIf="file.type !== null" color="primary" class="dwnbtn" (click)="fileDownload(file.id)">{{ file.filename }}</button> <button mat-raised-button disabled *ngIf="file.type === null">Aucun fichier chargé</button>
                </mat-card-actions>
            </mat-card>
        </div>

        <!-- PARTIE COFFRE FORT -->
        <div *ngIf="isCoffreFort()" fxLayout="column"fxFlex="100" fxLayoutAlign="center center">
            <div *ngIf="myDocList.length === 0">
                <mat-spinner></mat-spinner>
            </div>
            <mat-card *ngFor="let file of myDocList" fxLayout="column" fxFlex="100" style="margin-top:10px;" class="columnDiv">
                <mat-card-title fxFlex="100" fxLayoutAlign="center center">
                    {{ file.name }} <mat-icon *ngIf="file.unread" color="accent">notifications_unread</mat-icon>
                </mat-card-title>
                <mat-card-content fxFlex="100" class="columnDiv">
                    <mat-divider></mat-divider>
                    <p><strong>Propriétaire:</strong>&nbsp;{{ file.socioPrenom }} {{ file.socioNom }} ({{ file.socioTechID === null ? 'Adhérent #'+file.socioID : 'Socio #'+file.socioTechID }})</p>
                    <p><strong>Description:</strong> {{ file.description }}</p>
                </mat-card-content>
                <mat-card-actions fxFlex="100" fxLayoutAlign="center center">
                    <button mat-raised-button *ngIf="file.type !== null" class="dwnbtn" color="accent" (click)="myDocDownload(file.id)">{{ file.filename }}</button> <button mat-raised-button disabled *ngIf="file.type === null">Aucun fichier chargé</button>
                </mat-card-actions>
                <p style="font-style: italic;">Dernière mise à jour {{ file.update | date:'dd/MM/yyyy' }}</p>
            </mat-card>
        </div>
    </div>
</div>