import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { ConnectorService } from '../connector.service';
import { FormBuilder } from '@angular/forms';
import { Account, ProfileStatus, Socio } from '../account/account.dto';
import { environment } from '../../environments/environment';
import { Consultation, ConsultationStatus, ConsultationType, Jeton, JetonStatus } from './consultations.dto';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'app-consultations',
  templateUrl: './consultations.component.html',
  styleUrls: ['./consultations.component.scss']
})
export class ConsultationsComponent {
  account: Account = new Account();
  consultation: Consultation = new Consultation();
  jetonsList: Jeton[] = [];
  responses: number[] = [];
  volontariat: boolean[] = [];

  //inputs
  socioId:number = 0;
  consultationId:number = 0;
  jeton:Jeton|undefined = undefined;

  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    private fb: FormBuilder,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    if(this.connector.isConnected()){
      this.getAccount();
    }else{
      this.connector.disconnection();
    }
  }

  async getAccount(){
    await this.http.get<Account>(environment.backAPI+'/compte',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.account = result;
          this.account.socios = this.account.socios.sort(function(a, b) {
            return a.techId - b.techId;
          });
          if(this.account.socios.length === 1){
            this.changeSocio(this.account.socios[0].id);
          }
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  async changeSocio(myEvent:number){
    this.socioId = myEvent;
    this.responses = [];
    this.volontariat = [];
    this.consultation = new Consultation();
    const socio:Socio|undefined = this.account.socios.find(socio => socio.id === this.socioId);
    if(socio !== undefined){
      this.jetonsList = socio.jetons.sort(function(a, b) {
        return b.consultation.id - a.consultation.id;
      });
      if(this.jetonsList.length === 1){
        this.changeJeton(this.jetonsList[0].consultation.id);
      }
    }
  }

  statusJeton(status:JetonStatus){
    if(status === JetonStatus.EXPIRED){
      return "expiré";
    }
    if(status === JetonStatus.NEW){
      return "nouveau";
    }
    return "terminé";
  }

  jetonStyle(status:JetonStatus){
    if(status === JetonStatus.NEW){
      return {'font-weight': 'bold'};
    }
    return {'font-weight': 'lighter'};
  }

  changeJeton(myEvent:number){
    this.consultationId = myEvent;
    this.responses = [];
    this.volontariat = [];
    this.jeton = this.jetonsList.find(jeton => jeton.consultation.id === this.consultationId);
    this.consultation = this.jeton!.consultation;
    this.consultation.questions = this.consultation.questions.sort(function(a, b) {
      return a.ordre - b.ordre;
    });
  }

  displayDate(date:Date|undefined){
    if(date === undefined || date === null){
      return "aucune date";
    }
    const myDate:Date = new Date(date);
    return myDate.toLocaleDateString("fr-FR", { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' });
  }

  isCredits(){
    return this.consultation.type === ConsultationType.CREDITS && !this.isUsed() && !this.isExpired();
  }

  isValeur(){
    return this.consultation.type === ConsultationType.VALEURS && !this.isUsed() && !this.isExpired();
  }

  isNotOpen(){
    return (this.consultation.status === ConsultationStatus.CANCELED || this.consultation.status === ConsultationStatus.DRAFT)  && !this.isUsed() && !this.isExpired();
  }

  isExpired(){
    return this.jeton!.status === JetonStatus.EXPIRED;
  }

  isUsed(){
    return this.jeton!.status === JetonStatus.USED;
  }

  isSelected(id:number){
    return this.jeton!.status === JetonStatus.NEW && this.consultationId === id && this.consultation.type === ConsultationType.CREDITS;
  }

  remainingCredits(){
    if(this.consultation.type === ConsultationType.VALEURS){
      return 0;
    }
    let responsesTotal = 0;
    for(let response of this.responses){
      if(response !== undefined){
        responsesTotal += response;
      }
    }
    return this.consultation.credits - responsesTotal;
  }

  changeCredits(idQuestion:number, value:any){
    const previousValue:number = (this.responses[idQuestion] === undefined)?0:this.responses[idQuestion];
    this.responses[idQuestion] = Number(value.target.value);
    if(this.remainingCredits() < 0){
      this.responses[idQuestion] = previousValue;
      value.target.value = previousValue;
    }
  }

  changeValeurs(idQuestion:number, value:any){
    this.responses[idQuestion] = Number(value);
  }

  changeVolontariat(idQuestion:number, value:any){
    this.volontariat[idQuestion] = Boolean(value.checked);
  }

  async consultationValidation(){
    if(confirm("Êtes-vous sûr de vouloir valider vos choix?")){
      const treatment:ReponsesForm = {
        jetonId: this.jeton!.id,
        reponses: [],
        volontaire: []
      };
      for(let index of this.responses.keys()){
        if(this.responses[index] !== undefined){
          treatment.reponses.push({
            questionId: index,
            valeur: this.responses[index]
          });
        }
      }
      for(let index of this.volontariat.keys()){
        if(this.volontariat[index] !== undefined && this.volontariat[index]===true){
          treatment.volontaire.push(index);
        }
      }
      
      const _this = this;
      await this.http.post<{ code: String}>(environment.backAPI+'/consultations',treatment,{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ this.connector.token()
        }
      }).subscribe(
        async (result) => {
          if(result !== undefined && !(result instanceof HttpErrorResponse)){
            if(result.code === "question.invalid"){
              alert("Une des réponses envoyées fait référence à une question invalide");
            }
            if(result.code === "reponse.invalid"){
              alert("Une des réponses envoyées est invalide");
            }
            if(result.code === "credits.notenough"){
              alert("Vous tentez d'utiliser plus de crédits qu'autorisé");
            }
            if(result.code === "credits.notspent"){
              alert("Tous les crédits n'ont pas été utilisés");
            }
            if(result.code === "socio.invalid"){
              alert("Le socio utilisé est invalide");
            }
            if(result.code === "question.different"){
              alert("Une des questions concernées ne correspond pas à la consultation actuelle");
            }
            if(result.code === "jeton.invalid"){
              alert("Le jeton utilisé n'est plus valide");
            }
            if(result.code === "reponse.negative"){
              alert("Vous ne pouvez utiliser une valeur de crédits négative");
            }
            if(result.code === "OK"){
              await _this.getAccount();
              await _this.changeSocio(this.socioId);
              await _this.changeJeton(this.jeton!.id);
              setTimeout(() => _this.notificationsService.getInAppNotifications(), 1000);
            }
          }
          else{
            this.connector.disconnection();
          }
        },
        (error) => {
          this.connector.disconnection();
        }
      );
    }
  }
}

class ReponsesForm{
  jetonId: number = 0;
  reponses: { questionId: number, valeur: number }[] = [];
  volontaire: number[] = [];
}
