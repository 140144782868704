import {Injectable} from "@angular/core";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import { environment } from "../environments/environment";
import { Observable } from "rxjs";
import { ConnectorService } from "./connector.service";

export class InAppNotification {
  unreadDocs: number = 0;
  missingPronostics: number = 0;
  compteIssues: number = 0;
  socioIssues: number = 0;
  consultationsAvailable: number = 0;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    public notifications: InAppNotification = new InAppNotification();
    notificationsObs: Observable<InAppNotification> = new Observable<InAppNotification>();

    constructor(
        private http: HttpClient,
        private connector: ConnectorService
    )
    {}
  
    async getInAppNotifications(){
        const _this = this;
        _this.notificationsObs = await this.http.get<InAppNotification>(environment.backAPI+'/inapp-notifications',{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
        }
        });
        _this.notificationsObs.subscribe(
        (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
                _this.notifications = result;
            return result;
            }
            else{
            this.connector.disconnection();
            return [];
            }
        },
        (error) => {
            this.connector.disconnection();
            return [];
        }
        );
    }
}