<div fxLayout="column" [id]="'account'" isEditing="0" class="mainDiv">
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">Mon compte</h1>
    <div flex="" fxLayout="row" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Adresse email:</div>
        <div fxFlex="50">{{account.email}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement" fxLayoutAlign="left center">Pseudo:</div>
        <div fxFlex="50">
            <mat-form-field fxFlex="">
                <mat-label>Anonyme_{{account.id}}</mat-label>
                <input matInput placeholder="Pseudo" name="username" type="text" maxlength="15" [(ngModel)]="account.username" (ngModelChange)="setUsername($event)">
                <mat-hint>{{(account.username)?account.username.length:0}}/15</mat-hint>
                <button matSuffix mat-icon-button aria-label="Validation" (click)="saveUsername()" [ngClass]="(usernameValidation === null)?'':(usernameValidation)?'validationGreen':'validationRed'">
                    <mat-icon>{{(usernameValidation === null)?'done':(usernameValidation)?'verified':'error'}}</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div flex="" fxLayout="row" class="readModePhone" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Téléphone:</div>
        <div fxFlex="50">{{ (account.phone !== null && account.phone !== undefined)?"("+account.phone.regionCode+") "+account.phone.number.national:"Aucun" }}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editModePhone">
        <div fxFlex="100">
            <form [formGroup]="profileForm">
                <div formGroupName="phone" style="display: inherit;" fxLayout="column">
                    <mat-form-field class="phone_country" [fxFlex]="">
                        <mat-label>Pays du numéro</mat-label>
                        <mat-select formControlName="country" name="country" (selectionChange)="formatNumber()" [value]="(account.phone !== null)?account.phone.regionCode:''">
                            <mat-option *ngFor="let countryCode of countyCodes" [value]="countryCode.code">
                                {{countryCode.country}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="phone_number" [fxFlex]="">
                        <mat-label>N° téléphone</mat-label>
                        <input formControlName="number"
                            type="tel"
                            placeholder="Téléphone"
                            name="phone"
                            [value]="(account.phone !== null && account.phone.number !== null)?account.phone.number.national:''"
                            (input)="formatNumber()"
                            [errorStateMatcher]="phoneErrorMatcher"
                            matInput>
                        <mat-error *ngIf="!phoneGroup.valid">
                            Numéro valide: {{phoneHint}}
                        </mat-error>
                        <mat-hint>{{phoneHint}}</mat-hint>
                    </mat-form-field>
                </div>
            </form>
        </div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editModePhone">
        <div flex="">&nbsp;</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editModePhone">
        <div [fxFlex]="50" fxLayoutAlign="center">
            <button mat-raised-button color="warn" (click)="editModePhone(false)" class="editButton" aria-label="Annuler la modification">
                Annuler
            </button>
        </div>
        <div [fxFlex]="50" fxLayoutAlign="center">
            <button mat-raised-button color="primary" (click)="validatePhoneNumber()" class="editButton" aria-label="Valider la modification">
                Valider
            </button>
        </div>
    </div>
    <h1 flex="" fxLayout="row" fxLayoutAlign="center" class="readModePhone">
        <button mat-raised-button color="primary" fxLayout="row" fxLayoutAlign="center center" (click)="editModePhone(true)">Modifier mon téléphone <mat-icon *ngIf="!isPhoneSet()" color="warn">error</mat-icon></button>
    </h1>
    <div flex="">&nbsp;</div>
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">
        <button mat-raised-button color="accent" fxLayout="row" fxLayoutAlign="center center" (click)="openDialog('Modification du mot de passe', true)">Modifier mon mot de passe <mat-icon *ngIf="!isPasswordChanged()" color="warn">error</mat-icon></button>
    </h1>
    <div flex="">&nbsp;</div>
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="account.commandes.length > 0">
        <mat-accordion style="width:90%">
            <mat-expansion-panel (opened)="panelCommandeOpenState = true"
                                 (closed)="panelCommandeOpenState = false"
                                 fxLayout="column" fxLayoutAlign="center">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                    Résumé de mes commandes
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div  fxLayout="column" fxLayoutAlign="center" *ngFor="let commande of account.commandes">
                    <mat-divider flex="" fxLayout="row"></mat-divider>
                    <div flex="">&nbsp;</div>
                    <div flex="100"><strong>Commande #{{commande.idCommande}} - {{commande.date | date:'dd/MM/yyyy'}}</strong></div>
                    <div flex="100"><strong>Pour:</strong> {{commande.prenom}} {{commande.nom}} (Socio#{{commande.idTechSocio}})</div>
                    <div flex="100"><strong>Taille:</strong> {{commande.taille}} | <strong>Prix:</strong> {{commande.montant}}€ par {{commande.moyenPaiement}}</div>
                    <div flex="100"><strong>Livraison:</strong> {{commande.livraison}}</div>
                    <div flex="100" *ngIf="isDeliveryCommande(commande.livraison)"><strong>Adresse:</strong> {{commande.adresse}} - {{commande.codePostal}} {{commande.commune}} ({{commande.pays}})</div>
                    <div flex="">&nbsp;</div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div flex="">&nbsp;</div>
    <h1 flex="" fxLayout="row" fxLayoutAlign="center">Mes profils</h1>
    <div flex="">&nbsp;</div>
</div>

<div fxLayout="column" class="sociodetails mainDiv" *ngFor="let socio of account.socios" [id]="'socio-'+socio.id" isEditing="0">
    <mat-divider flex="" fxLayout="row"></mat-divider>
    <div flex="">&nbsp;</div>
    <div flex="">&nbsp;</div>
    <h2 flex="" fxLayout="row" fxLayoutAlign="left">{{ (socio.roles.isSocio ) ? 'Socio#'+socio.techId : '' }} {{ (socio.roles.isSocio && socio.roles.isAdherent)?'-':'' }} {{ (socio.roles.isAdherent ) ? 'Adhérent' + ((socio.roles.isSocio) ? '' : '#'+socio.id) : '' }} 
        <button mat-icon-button color="primary" class="editButton readMode" aria-label="Modifier les infos de ce profil" (click)="editMode(socio.id,true)">
            <mat-icon>edit</mat-icon>
        </button>
        <mat-icon *ngIf="!isCountrySet(socio)" color="warn">error</mat-icon>
    </h2>
    <div flex="" fxLayout="row" class="readModeForced" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Nom:</div>
        <div fxFlex="50">{{socio.nom}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editModeDisabled">
        <mat-form-field fxFlex="100">
            <mat-label>Nom de famille</mat-label>
            <input matInput placeholder="Nom de famille" name="nom" type="text" [(ngModel)]="socio.nom">
        </mat-form-field>
    </div>
    <div flex="" fxLayout="row" class="readModeForced" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Prénom:</div>
        <div fxFlex="50">{{socio.prenom}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editModeDisabled">
        <mat-form-field fxFlex="100">
            <mat-label>Prénom</mat-label>
            <input matInput placeholder="Prénom" name="prenom" type="text" [(ngModel)]="socio.prenom">
        </mat-form-field>
    </div>

    <div flex="" fxLayout="row" class="readModeForced" fxLayoutAlign="left" *ngIf="socio.maillot">
        <div fxFlex="50" class="mainElement">Présence maillot:</div>
        <div fxFlex="50">Oui</div>
    </div>

    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Adresse email:</div>
        <div fxFlex="50">{{socio.mail}}</div>
    </div>
    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="50" class="mainElement">Date de naissance:</div>
        <div fxFlex="50">{{socio.date_naissance | date:'dd/MM/yyyy'}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
        <mat-form-field fxFlex="100">
            <mat-label>Date de naissance</mat-label>
            <input matInput [matDatepicker]="picker" name="date_naissance" [(ngModel)]="socio.date_naissance">
            <mat-hint>JJ/MM/AAAA</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left">
        <div fxFlex="" class="mainElement">Adresse postale:</div>
    </div>
    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="20">&nbsp;</div>
        <div fxFlex="30">Rue:</div>
        <div fxFlex="50">{{socio.adresse}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
        <mat-form-field fxFlex="100">
            <mat-label>Rue</mat-label>
            <input matInput placeholder="Rue" name="adresse" type="text" [(ngModel)]="socio.adresse">
        </mat-form-field>
    </div>
    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="20">&nbsp;</div>
        <div fxFlex="30">Code postal:</div>
        <div fxFlex="50">{{socio.code_postal}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
        <mat-form-field fxFlex="100">
            <mat-label>Code postal</mat-label>
            <input matInput placeholder="Code postal" name="code_postal" type="text" [(ngModel)]="socio.code_postal">
        </mat-form-field>
    </div>
    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="20">&nbsp;</div>
        <div fxFlex="30">Ville:</div>
        <div fxFlex="50">{{socio.ville}}</div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
        <mat-form-field fxFlex="100">
            <mat-label>Ville</mat-label>
            <input matInput placeholder="Ville" name="ville" type="text" [(ngModel)]="socio.ville">
        </mat-form-field>
    </div>
    <div flex="" fxLayout="row" class="readMode" fxLayoutAlign="left">
        <div fxFlex="20">&nbsp;</div>
        <div fxFlex="30">Pays:</div>
        <div fxFlex="50" fxLayout="row">{{socio.pays}} <mat-icon *ngIf="!isCountrySet(socio)" color="warn">error</mat-icon></div>
    </div>
    <div flex="" fxLayout="row" fxLayoutAlign="left" class="editMode">
        <mat-form-field fxFlex="100">
            <mat-label>Choisir le pays</mat-label>
            <mat-select [value]="capitalizeFirstLetter(socio.pays)" [(ngModel)]="socio.pays" name="pays">
                <mat-option *ngFor="let country of countries" [value]="country.name">
                    {{country.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div flex="" class="editMode">
        Valider les modifications ? &nbsp;
        <button mat-fab color="warn" class="validateButtons" aria-label="Annuler les modifications" (click)="editMode(socio.id,false)">
            <mat-icon>cancel</mat-icon>
        </button>
        <button mat-fab color="primary" class="validateButtons rightButton" aria-label="Valier les modifications" (click)="validate(socio.id)">
            <mat-icon>task_alt</mat-icon>
        </button>
    </div>

    <div flex="">&nbsp;</div>
    <div flex="">&nbsp;</div>

    <p style="font-style: italic; text-align: center;">Vos cartes sont désormais disponibles dans <a [routerLink]="['/documents']">vos documents</a>, section Coffre Fort</p>

    <div flex="">&nbsp;</div>
    <div flex="">&nbsp;</div>

</div>
