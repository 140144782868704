import { Component, Inject } from '@angular/core';
import { Account, Commande, ProfileStatus, Socio } from './account.dto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ConnectorService, LogInBackDto } from '../connector.service';
import { DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import { COUNTRIES } from './country-data-store';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';
import { NgFor, NgIf } from '@angular/common';
import { ParsedPhoneNumber, parsePhoneNumber, getExample } from 'awesome-phonenumber';
import { ISO_3166_1_CODES } from './country-codes';
import { ErrorStateMatcher } from '@angular/material/core';
import { NotificationsService } from '../notifications.service';

export interface DialogData {
  password: string;
  confirmation: string;
  title: string;
  display: boolean;
}

export const phoneValidator: ValidatorFn|ValidationErrors|null = (control: FormGroup): ValidationErrors | null => {
  const country = control.get('country');
  const num = control.get('number');
  if (num?.value && country?.value && !(parsePhoneNumber( num.value, { regionCode: country.value } ).valid)) {
      return {invalidPhone: true};
  } else {
      return null;
  }
};

/**
 * {@see ErrorStateMatcher} used to update the error state of the
 * phone number when the country or phone number changes.
 */
export class PhoneErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if(control === null){
      return true;
    }
      return !!(control.value && control.touched && !control?.parent?.valid);
  }
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
  ],
})
export class AccountComponent {
  countyCodes = ISO_3166_1_CODES;
  public countries:any = COUNTRIES;
  account: Account = new Account();
  public acceptMaillot:string = "Je suis d'accord";
  public refuseMaillot:string = "Je refuse";
  public displayOk:boolean[] = [];
  profileForm = this.fb.group({
      phone: this.fb.group({
          country: ['FR'],
          number: ['']
      }, {validators: phoneValidator})
  });
  phoneErrorMatcher = new PhoneErrorMatcher();
  panelCommandeOpenState = false;

  usernameValidation:Boolean|null = null;
  
  constructor(
    private http: HttpClient,
    private connector: ConnectorService,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    private _adapter: DateAdapter<any>,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    if(this.connector.isConnected()){
      this.getAccount();
    }else{
      this.connector.disconnection();
    }
    this._locale = 'fr';
    this._adapter.setLocale(this._locale);
  }

  get phoneCountryControl() {
    return this.profileForm.get('phone.country') as FormControl;
  }

  get phoneNumberDigits(): string {
      return this.phoneNumberControl.value.replace(/\D/g, '');
  }

  get phoneNumber(): ParsedPhoneNumber {
      return parsePhoneNumber( this.phoneNumberDigits, { regionCode: this.phoneCountryControl.value } );
  }
  // FormControl Getters
  get phoneGroup() {
      return this.profileForm.get('phone') as FormControl;
  }
  /**
   * Generate a hint using the {@see PhoneNumber} getExample method
   * with the currently selected country.
   */
  get phoneHint(): string {
    const result:string|undefined = getExample(this.phoneCountryControl.value).number?.national;
    return (result !== undefined)?result:"";
  }

  get phoneNumberControl() {
    return this.profileForm.get('phone.number') as FormControl;
  }

  formatNumber() {
      const natNum = this.phoneNumber.number?.national;
      this.phoneNumberControl.setValue((natNum) ? natNum : this.phoneNumberDigits);
  }

  isPhoneSet():boolean{
    if(this.account.phone === null || this.account.phone.valid === false){
      return false;
    }
    return true;
  }

  isPasswordChanged():boolean{
    if(this.notificationsService.notifications.compteIssues === 2){
      return false;
    }else if(this.notificationsService.notifications.compteIssues === 1 && this.isPhoneSet()){
      return false;
    }
    return true;
  }

  isCountrySet(socio:Socio):boolean{
    return socio.pays !== null && socio.pays.length > 3;
  }

  setUsername(username:string){
    this.account.username = username.replace(/[^a-zA-Z0-9_]/g, '');
  }

  async saveUsername(){
    await this.http.post<Boolean>(environment.backAPI+'/compte/username',{ username: this.account.username },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          const _this = this;
          this.usernameValidation = result;
          setTimeout(() => {
            _this.usernameValidation = null;
          }, 2000);
          if(!result){
            alert("Mise à jour du pseudo non prise en compte");
          }
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  async getAccount(){
    const _this = this;
    await this.http.get<Account>(environment.backAPI+'/compte',{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.account = result;
          this.account.socios = this.account.socios.sort(function(a, b) {
            return a.techId - b.techId;
          });
          if(this.account.status === ProfileStatus.FIRSTCONNECTION){
            this.openDialog('Première connexion', false);
          }
          setTimeout(() => {
            _this.notificationsService.getInAppNotifications();
          }, 1000);
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  async validatePhoneNumber(){
    if(this.phoneNumber.valid){
      await this.http.post<Account>(environment.backAPI+'/compte/phone', { phone : this.phoneNumber },{
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ this.connector.token()
        }
      }).subscribe(
        (result) => {
          if(result !== undefined && !(result instanceof HttpErrorResponse)){
            this.editModePhone(false);
            this.getAccount();
          }
          else{
            this.connector.disconnection();
          }
        },
        (error) => {
          this.connector.disconnection();
        }
      );
    }else{
      alert("Le format du numéro de téléphone est erroné");
    }
  }

  editModePhone(value:boolean){
    const idToSelect:string = "#account";
    const el:Element|null = document.body.querySelector(idToSelect);
    if(el !== null){
      el.setAttribute("isEditing",(value)?"1":"0");
    }
  }

  editMode(id:number, value:boolean){
    const idToSelect:string = "#socio-"+id;
    const el:Element|null = document.body.querySelector(idToSelect);
    if(el !== null){
      el.setAttribute("isEditing",(value)?"1":"0");
    }
  }

  getFieldValue(parentId:string, name:string){
    let el:HTMLInputElement|null = document.body.querySelector(parentId+" input[name="+name+"]");
    if(el !== null){
      return el.value;
    }
    el = document.body.querySelector(parentId+" mat-select[name="+name+"]");
    if(el !== null){
      return el.getAttribute('ng-reflect-value');
    }
    return null;
  }

  isDeliveryCommande(value:string):boolean{
    return value !== 'Retrait boutique';
  }

  async validate(id:number){
    const idToSelect:string = "#socio-"+id;
    const accountToEdit = this.account.socios.filter(socio => socio.id === id)[0];
    await this.http.post<Account>(environment.backAPI+'/socio', {
      id: id,
      nom: accountToEdit.nom,
      prenom:accountToEdit.prenom,
      date_naissance:this.getFieldValue(idToSelect,"date_naissance")?.replaceAll("/","-"),
      adresse:accountToEdit.adresse,
      code_postal:accountToEdit.code_postal,
      ville:accountToEdit.ville,
      pays:accountToEdit.pays
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+ this.connector.token()
      }
    }).subscribe(
      (result) => {
        if(result !== undefined && !(result instanceof HttpErrorResponse)){
          this.editMode(id, false);
          this.getAccount();
        }
        else{
          this.connector.disconnection();
        }
      },
      (error) => {
        this.connector.disconnection();
      }
    );
  }

  capitalizeFirstLetter(str:string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  openDialog(title:string, display:boolean): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      data: {title: title, password: "", display: display},
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result !== undefined){
        this.http.post<LogInBackDto>(environment.backAPI+'/compte/password', { password : result }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ this.connector.token()
          }
        }).subscribe(
          (result) => {
            if(result !== undefined && !(result instanceof HttpErrorResponse)){
              this.connector.initSession(result);
              alert("Votre mot de passe a bien été modifié");
              this.getAccount();
            }
            else{
              this.connector.disconnection();
            }
          },
          (error) => {
            this.connector.disconnection();
          }
        );
      }
    });
  }
}


@Component({
  selector: 'password-page-dialog',
  templateUrl: 'password-page-dialog.html',
  styleUrls: ['./password-page-dialog.css'],
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatCardModule, MatIconModule, FlexLayoutModule, NgIf],
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
